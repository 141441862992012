<template>
  <div class="chart userGoal">
    <LineChart :chartData="chartData" :options="options" v-if="loaded"/>
  </div>
</template>

<script>
import { ref, onMounted, defineComponent } from 'vue'
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import {colors} from "@/services/colors";
import axios from 'axios';
import config from '@/services/config';

Chart.register(...registerables);

export default defineComponent({
  name: 'GoalChart',
  components: { LineChart },
  props: {
    chartData: {}
  },
  setup(){
    const options = ref({});
    let loaded = ref(false);
    onMounted(async ()=>{

      options.value = {
        scales: {
          x: {
            type: 'category',
            ticks: {
              autoSkip: true,
              maxTicksLimit:60,
              maxRotation: 90,
              minRotation: 90,
              font: {
                size: 10
              },
             labelOffset: -5

            }
          },

        }
      };

      //console.log(JSON.stringify(testData.value));
      loaded.value = true;

      return {
        loaded, options
      }

    });

    return {
      loaded, options
    }

  },




});
</script>


<style scoped>

.chart{
  margin-top:20px;
  width:100% !important;

}

canvas{
  width:100% !important;

}



</style>
