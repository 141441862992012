
<template>
  <div class="mainContainer container" id="mainContainer">

    <div style="display:flex;">
      <back-button/>
      <h6 style="margin:auto;padding-right:50px;font-size:20px;">{{pageTitle}}</h6>
    </div>
    <hr>

    <div class="form-group avatarDiv">
      <carousel :items-to-show="2.1" :transition="300" :wrap-around="true" v-model="currentSlide" >
        <slide v-for="(user,idx) in this.familyUsers" :key="idx" >
          <div class="carousel__item"><img :src=user.AVATAR_URL></div>
        </slide>

        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>


    <div class="alert alert-danger d-flex expireBanner" v-if="account.EXPIRE_STATUS === '4'"><font-awesome-icon  class="expired expireIcon" :icon="['fas', 'exclamation-circle']"/> User account has expired! <div class="btn btn-success extend" @click="this.$root.gotoRoute({ path: '/extenduser' })">Extend</div></div>
    <div class="alert alert-warning d-flex expireBanner" v-if="account.EXPIRE_STATUS === '1'"><font-awesome-icon class="expiring expireIcon" :icon="['fas', 'exclamation-triangle']"/> User account is expiring! <div class="btn btn-success extend" @click="this.$root.gotoRoute({ path: '/extenduser' })">Extend</div></div>

    <hr>

    <div style="display:flex;justify-content: center;" v-if="isAdminOrMyself && account.EXPIRE_STATUS !== '4'">
      <button class="btn tranBtn inBtn" data-trantype="in" @click="tranBtnClicked('in')" :class="{active:inOut === 'in'}" v-if="this.isAdmin" ><font-awesome-icon :icon="['fas', 'plus-circle']" /> Money In </button>
      <button class="btn tranBtn outBtn" data-trantype="out" @click="tranBtnClicked('out')" :class="{active:inOut === 'out'}" ><font-awesome-icon :icon="['fas', 'minus-circle']" /> Money Out </button>
    </div>

    <hr>

    <div class="userBAs">
      <ul>
        <li class="userBA d-flex clickable" v-for="(userAccount) in activeUserBas" :key="userAccount.BA_ID" @click="viewAccountTrans(userAccount.BA_ID)">
          <div class="info">
            <font-awesome-icon v-if="userAccount.EXPIRE_STATUS === '1'" class="expiring" :icon="['fas', 'exclamation-triangle']"/>
            <font-awesome-icon  v-if="userAccount.EXPIRE_STATUS === '4'" class="expired" :icon="['fas', 'exclamation-circle']"/>
            <div><font-awesome-icon class="accountIcon" :icon="['fas', 'university']"/> {{userAccount.ACCOUNT_NAME}}</div>
          </div>
          <div class="info">{{this.vueNumberFormat(userAccount.BALANCE)}}</div>
        </li>
        <li class="totalLine clickable">
          <div class="info">
            <div>Total</div>
          </div>
          <div class="info">{{this.vueNumberFormat(activeUserBalance)}}</div>
        </li>

      </ul>

    </div>


    <hr>

    <div class="userBA d-flex clickable savingGoalLn" @click="goToSavingGoal(activeUser.ID)"><div class="info"><font-awesome-icon class="accountIcon" :icon="['fas', 'piggy-bank']"/> Saving Goal</div><div>{{activeUser.SAVING_GOAL}}</div></div>

    <div class="goalChart d-flex"  v-if="chartDataLoaded && this.userGoalChartData[activeUser.ID]">
      <GoalChart :chartData="this.userGoalChartData[activeUser.ID]"/>
    </div>

    <hr>


      <div class="transHistory" id="tranHistory">
        <div class="sectionHeader" id="tranHistoryTitle">Transaction History & Upcoming</div>

        <section class="datePickerDiv" >
          <div style="margin-right:20px;">Show transactions before</div>
          <div >
            <DatePicker v-model="transAsOfDate" :class="{ 'hasError': tranDateHasError }"  />
          </div>
        </section>

        <section>
          <div>Account Filter</div>
          <select class="form-control validate" id="tranBa" v-model="baId" @change="hideTrans()">
            <option value="">All Accounts</option>
            <option v-for="(userTranAccount) in activeUserBas" :key="userTranAccount.BA_ID" :value="userTranAccount.BA_ID">{{userTranAccount.ACCOUNT_NAME}}</option>
          </select>

        </section>

        <section class="showBudgetDiv clickable" @click="toggleShowBudget">
          <div class="showBudgetTxt">Show Upcoming</div>
          <div>
            <font-awesome-icon v-if="showBudgetTrans" class="toggleFa" :icon="['fas', 'toggle-on']" />
            <font-awesome-icon v-else class="toggleFa" :icon="['fas', 'toggle-off']" />
          </div>

        </section>






        <div class="loadTransDiv clickable" @click="reloadTrans()" v-if="viewTrans">
          <div class="btn loadTransBtn">View Transactions</div>
        </div>
        <div class="loadTransDiv clickable" @click="hideTrans()" v-else>
          <div class="btn loadTransBtn">Hide Transactions</div>
        </div>


      </div>

      <div>
        <ul class="transactions mt-2 list2" v-if="transactionLoaded">
          <li class="transaction " :class="{'isBudget': transaction['SCHEDULE_ID']}" v-for="transaction in transactions" :key="transaction.ID" >
            <transaction :isAdmin="isAdmin" :userInfo="userInfo" :transaction="transaction" :baId="baId" @editTran="gotoEditTran" @showDeleteModalFunc="showDeleteModalFunc" @convertTran="convertTran" ></transaction>
          </li>
        </ul>
      </div>

    <div class="clickable loadMore" @click="loadMore()" v-if="!allLoaded && transactionLoaded">Load more</div>
    <div class="clickable loadMore" v-if="allLoaded && transactionLoaded">No more transaction to display</div>


    <hr>

    <div class="scheduleSection">
      <div class="sectionHeader">Schedules</div>
      <div>
        <ul id="scheduleUl">
          <li class="scheduleLi clickable" v-for="(schedule, idx) in mySchedules" :key="schedule.ID">
            <div class="scheduleTxt" @click="toggleSchedule(idx)">
              <font-awesome-icon :icon="['fas', 'clock']"/>
               #{{schedule.ID}}
              <span>{{genScheduleTxt(schedule)}}</span>
            </div>
            <div class="scheduleDetails d-flex " @click="toggleSchedule(idx)">
              <div class="scheduleDetailsLeft d-flex">
                <div class="inout" :class="{in:tranInOut(schedule)==='IN', out:tranInOut(schedule)==='OUT'}"><div>{{tranInOut(schedule)}}</div></div>
                <div>
                  <div class="account" :class="{myAccount:schedule.USER_ID === this.activeUser.ID}">{{schedule.ACCOUNT_NAME}}</div>
                  <div class="accountRelated" :class="{myAccount:schedule.USER_ID_RELATED === this.activeUser.ID}">{{schedule.ACCOUNT_NAME_RELATED}}</div>
                </div>
              </div>

              <div class="amountDiv">
                <div class="amount">{{this.vueNumberFormat(parseFloat(schedule.AMOUNT))}}</div>
                <div class="amount">{{this.vueNumberFormat(-parseFloat(schedule.AMOUNT))}}</div>
              </div>
            </div>

            <div class="tranDesc">
              {{schedule.DETAILS}}
            </div>


            <div class="d-flex scheduleActions" v-if="activeScheduleIdx === idx">
              <div class="btn btn-danger delTranBtn scheduleActionBtn" @click="showDeleteScheduleModal=true"><font-awesome-icon :icon="['fas', 'trash']"/></div>
              <div class="btn btn-warning editTranBtn scheduleActionBtn" @click="gotoEditTran({tranId:schedule.ID, isSchedule:'1'})"><font-awesome-icon :icon="['fas', 'pencil-alt']"/></div>

            </div>
            <div class="scheduleTransDiv" v-if="activeScheduleIdx === idx">
              <ul v-if="activeScheduleIdx === idx">Next 10 scheduled transaction dates:
                <li class="scheduleTranLi clickable" v-for="idx2 in Math.min(10,activeScheduleTrans.length)" :key="idx2">
                  {{activeScheduleTrans[idx2-1].TRAN_DATE}}
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div class="loadTransDiv clickable" @click="goToAddNewSchedule()" v-if="isAdminOrMyself">
        <div class="btn loadTransBtn">Add Schedule</div>
      </div>

    </div>






  </div>


  <div class="modalParent">
    <vue-final-modal v-model="showDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Are you sure you want to delete this transaction?</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delYes" @click="delTran"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn delConfirmBtn delNo" @click="showDeleteModal=false"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>
      </div>
    </vue-final-modal>
  </div>

  <div class="modalParent">
    <vue-final-modal v-model="showDeleteScheduleModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Are you sure you want to delete this schedule?</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delYes" @click="delSchedule"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn delConfirmBtn delNo" @click="showDeleteScheduleModal=false"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>
      </div>
    </vue-final-modal>
  </div>


</template>

<script>
import DatePicker from '@/components/DatePicker';
import Transaction from '@/components/Transaction';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import {VueFinalModal } from 'vue-final-modal';
import GoalChart from "@/components/GoalChart";
import {colors} from "@/services/colors";
import axios from "axios";
import config from "@/services/config";
import {RRule} from "rrule";



var d = new Date();
//console.log(d.toLocaleDateString());
d.setMonth(d.getMonth() + 1);

export default {
  name: 'Transactions',
  /*props: {
    isBudget:String
  },*/
  components: {
    Transaction,
    DatePicker,
    Carousel,
    Slide,
    Navigation,
    GoalChart,
    VueFinalModal

  },
  data(){
    return{
      account: {},
      userInfo:{},
      familyUsers:{},
      familyBas:[],
      isAdmin:false,
      isBudget:null,

      currentSlide:0,

      baId:'',
      userId:null,
      userIdx:null,
      tranId:null,
      //pageTitle:'',
      transactions:[],
      tranMode:false,
      inOut:'',
      tranBaId:null,
      tranBaName:null,
      convertTranMode:false,
      showBudgetTrans:false,
      transAsOfDate:d,

      familyCurrency:'$',

      balanceChartData:{},
      balanceChartLabels:{},
      userGoalChartData:{},
      chartDataLoaded:false,

      initialTransLoad:false,
      transactionLoaded:false,

      familyAccounts:{},
      familyRelatedAccounts:{},
      userTranAccounts:{},
      numIncreMode:'plus',
      tranAmount: '0',
      tranAmountHasError: false,
      incrementAmount:5,
      //saveBtnTxt:'Add New Transaction',
      increments: [
        {id:1, name:'$5',amount:5},
        {id:2, name:'$1',amount:1},
        {id:3, name:'¢25',amount:0.25},
        {id:4, name:'¢10',amount:0.10},
        {id:5, name:'¢5',amount:0.05},
        {id:6, name:'¢1',amount:0.01}
      ],
      incrementActiveId:1,
      tranDesc:'',
      tranDescHasError:false,
      relatedAccountId: '',
      autoConvert:'0',
      tranDate:  new Date(),
      tranDateYmd:'',
      tranDateHasError:false,
      futureTranDate:false,
      relatedAcctHasError:false,
      endDate:  null,
      repeatTimesOptions:12,
      repeatTimes:1,
      repeatFrequency:'oneoff',
      repeatFrequencyOptions:[
        {id:1, name:'One Off',value:'oneoff'},
        {id:2, name:'Daily',value:'daily'},
        {id:3, name:'Weekly',value:'weekly'},
        {id:4, name:'Monthly',value:'monthly'},
        {id:5, name:'Quarterly',value:'quarterly'},
        {id:6, name:'Semi-annually',value:'semiannually'},
        {id:7, name:'Annually',value:'annually'},

      ],

      showDeleteModal:false,
      showPickBasModal:false,
      showDeleteScheduleModal:false,


      loading:false,

      allLoaded:false,
      batchSize:10,
      batch:0,

      upcomingTrans:[],
      upcomingTransLoaded:false,

      viewTrans: true,
      schedules:[],
      activeScheduleIdx:null,



    }
  },

  computed:{
/*    saveBtnTxt() {
      return this.isBudget === '1' ? 'Add New Budget' : 'Add New Transaction';
    },*/

    activeUser(){
      return this.familyUsers[this.currentSlide];
    },

    pageTitle(){
      return this.activeUser.FULL_NAME;
    },

    activeUserBas(){
      return this.familyBas.filter(ba => ba.USER_ID === this.activeUser.ID);
    },

    activeUserBalance(){
      return this.activeUserBas.reduce((a,b)=> a + parseFloat(b.BALANCE),0);
    },

    transAsOfDateYmd(){
      return this.transAsOfDate.toLocaleDateString('en-CA');
    },

    mySchedules(){
      return this.schedules.filter(schedule=>schedule.USER_ID === this.activeUser.ID || schedule.USER_ID_RELATED === this.activeUser.ID );
    },

    activeSchedule(){
      return this.mySchedules[this.activeScheduleIdx];
    },

    activeScheduleTrans(){
      if(this.activeScheduleIdx !== null){
        var schedules = [this.mySchedules[this.activeScheduleIdx]];
        return this.$root.getAllUpcomingTrans(schedules, false, false, this.activeUser.ID)
      }else{
        return [];
      }

    },

    activeTran(){
      return this.transactions.filter(tran=>tran.ID === this.tranId)[0];
    },

    isAdminOrMyself(){
      return this.isAdmin || this.userInfo.ID === this.activeUser.ID;
    }

  },

  watch: {
    currentSlide(newValue,oldValue){
      this.transactions = [];
      this.transactionLoaded = false;
      this.viewTrans = true;
      this.baId = '';
      this.batch = 0;
      this.allLoaded = false;
      this.upcomingTransLoaded = false;

    },
    transAsOfDate(newValue,oldValue){
      this.hideTrans();
    },

  },

  methods:{

    loadData:async function(){
      let res = await this.$root.doRefresh();

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.userInfo = userInfo;
      this.isAdmin = userInfo && userInfo.FAMILY_ADMIN === '1';

      this.familyUsers = JSON.parse(localStorage.getItem('familyUsers'));
      if(!this.isAdmin){
        this.familyUsers = this.familyUsers.filter(user=>user.ID === this.userId);
      }

      this.schedules = JSON.parse(localStorage.getItem('schedules'));

      this.renderUpdates();

      this.currentSlide = this.familyUsers.findIndex(user => {
        return user.ID === this.userId;
      });

      this.increments = [
        {id:1, name:this.familyCurrency+'5',amount:5},
        {id:2, name:this.familyCurrency+'1',amount:1},
        {id:3, name:'¢25',amount:0.25},
        {id:4, name:'¢10',amount:0.10},
        {id:5, name:'¢5',amount:0.05},
        {id:6, name:'¢1',amount:0.01}
      ];


    },

    getTransactions: function(){
      let loader = this.$loading.show();

      if(this.showBudgetTrans && !this.upcomingTransLoaded){

        //console.log(this.schedules);
        //console.log(this.activeUser.ID);
        var upcomingTrans = this.$root.getAllUpcomingTrans(this.schedules, false, false, this.activeUser.ID);

        if(this.baId !== ''){
          upcomingTrans = upcomingTrans.filter(tran=>tran.ACCOUNT_ID === this.baId || tran.ACCOUNT_ID_RELATED === this.baId )
        }

        upcomingTrans = upcomingTrans.filter(tran=>tran.TRAN_DATE <= this.transAsOfDateYmd);
        //console.log(this.transAsOfDate);
        //console.log(upcomingTrans);
        upcomingTrans.sort((a,b)=>a.TRAN_DATE.localeCompare(b.TRAN_DATE)).reverse();
        //console.log(upcomingTrans);

        this.transactions.push(...upcomingTrans);
        this.upcomingTransLoaded = true;
      }




      this.axios
          .post(this.$hostApi,{task:'getTransactions',baId:this.baId, transAsOfDate:this.transAsOfDate.toLocaleDateString('en-CA'), showBudgetTrans:this.showBudgetTrans, userId: this.activeUser.ID, batchSize:this.batchSize,batch:this.batch},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            if(response.data.length > 0){
              this.transactions.push(...response.data);
              this.batch++;
            }else{
              this.allLoaded = true;
            }

            loader.hide();
            this.transactionLoaded = true;
            this.viewTrans = false;

            if(this.initialTransLoad){
              setTimeout(function(){
                let ele = document.getElementById('tranHistory');
                window.scrollTo(0, ele.offsetTop - 60);
              }, 600);

            }

          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },



    delTran: async function(){
      this.$root.playClickSound();

      await this.axios
          .post(this.$hostApi,{task:'delTran', tranBaId:this.activeTran.ACCOUNT_ID, tranId:this.tranId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      this.showDeleteModal = false;
      this.chartDataLoaded = false;
      //await this.$root.doRefresh();
      await this.$root.doRefresh()
      this.renderUpdates();
      this.transactions = this.transactions.filter(t => t.ID !== this.tranId);

    },

    delSchedule: async function(){
      this.$root.playClickSound();
      await this.axios
          .post(this.$hostApi,{task:'delSchedule', scheduleId:this.activeSchedule.SCHEDULE_ID},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });
      this.showDeleteScheduleModal = false;
      this.chartDataLoaded = false;
      this.activeScheduleIdx = null;

      //await this.$root.doRefresh();

      await this.$root.doRefresh();
      this.renderUpdates();

    },


    getPageTitle:function(){
      this.pageTitle = this.isBudget && this.isBudget === '1' ? 'Budgeting - ' + this.account.FULL_NAME : 'New Transaction - ' + this.account.ACCOUNT_NAME;
    },

    tranBtnClicked:function(btn){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/tran/new', query: { userId: this.activeUser.ID, io: btn }});


    },

    gotoNewTran(baId){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/tran/new', query: { userId: this.activeUser.ID, tranBaId: baId, io: 'in' }});
    },

    convertTran:function(tranId){
      this.$root.gotoRoute({ path: '/tran/convert', query: { userId: this.activeUser.ID, tranId:tranId } })
    },

    gotoEditTran:function({tranId, isSchedule}){
      this.$root.gotoRoute({ path: '/tran/edit', query: { userId: this.activeUser.ID, tranId:tranId, isSchedule:isSchedule } })
    },

    goToSavingGoal:function(userId){
      this.$root.gotoRoute({ path: '/settings/family/savinggoal', query: { userid: userId } })
    },



    switchActualBudget(tranType){
      this.$root.playClickSound();
      this.isBudget = tranType === 'actual' ? '0' : '1';
    },

    toggleShowBudget(){
      this.showBudgetTrans = !this.showBudgetTrans;
      this.hideTrans();
    },

    toggleAutoConvert(){
      this.autoConvert = this.autoConvert === '1' ? '0' : '1';
    },


    amountAdj:function(dir){
      this.$root.playClickSound();
      if(dir==='plus'){
        this.tranAmount = parseFloat(this.numDeformat(this.tranAmount)) + parseFloat(this.incrementAmount);
      }
      if(dir==='minus'){
        this.tranAmount = parseFloat(this.numDeformat(this.tranAmount)) - parseFloat(this.incrementAmount);
        if(this.tranAmount < 0)  this.tranAmount = 0;
      }

    },
    set2zero:function(){
      this.tranAmount = 0;
    },
    onSetIncrementAmount(increment) {
      this.incrementActiveId = increment.id;
      this.incrementAmount = increment.amount;
    },
    numDeformat:function(n){
      return parseFloat(n.replace(/,/g, ''));
    },
    getLocalDateString:function(d){
      return d.toLocaleDateString('en-CA');
    },





    getBaById:function(baId){
      return this.familyAccounts.filter(ba => ba.ID === baId)[0];
    },

    getUserGoalChartData:function(userId){
      let userData = {};
      let userDataDatasets = this.balanceChartData.datasets.filter(userData => userData.userId === userId);
      let userDataDatasetsData = userDataDatasets[0].data;
      let goalData = {};
      goalData['borderColor'] = 'rgba(230,25,75,0.5)';
      goalData['backgroundColor'] = 'rgba(230,25,75,0.5)';
      goalData['label'] = 'Saving Goal';
      goalData['data'] = userDataDatasetsData.map(a => this.familyUsers.filter(user => user.ID === userId)[0].SAVING_GOAL);

      userDataDatasets.push(goalData);
      userData['datasets'] = userDataDatasets;
      userData['labels'] = this.balanceChartData.labels;
      //console.log(userData);

      return userData;
    },

    reloadTrans(){
      this.$root.playClickSound();
      this.transactions = [];
      this.batch = 0;
      this.initialTransLoad = false;
      this.upcomingTransLoaded = false;
      this.getTransactions();
      this.viewTrans = false;
    },

    hideTrans(){
      this.transactionLoaded = false;
      this.viewTrans=true;
    },

    viewAccountTrans(baId){
      this.$root.playClickSound();
      this.baId = baId;
      this.reloadTrans();
      //location.hash = '#tranHistory';
      document.getElementById('tranHistory').scrollIntoView();
    },

    showDeleteModalFunc:function(tranId){
      this.$root.playClickSound();
      this.showDeleteModal = true;
      this.tranId = tranId;
    },

    renderUpdates(){


      this.balanceChartData = JSON.parse(localStorage.getItem('balanceChartData'));
      //console.log(this.balanceChartData);
      this.balanceChartData.datasets.forEach((user, index )=>{

        this.userGoalChartData[user.userId] = this.getUserGoalChartData(user.userId);
      })
      this.chartDataLoaded = true;

      this.familyBas = JSON.parse(localStorage.getItem('familyBas'));
      this.schedules = JSON.parse(localStorage.getItem('schedules'));

    },

    loadMore:function(){
      this.initialTransLoad = false;
      this.getTransactions();
    },

    genScheduleTxt(schedule){
      var rruleDb = JSON.parse(schedule.SCHEDULE);
      //console.log('rruleDb is ');
      //console.log(rruleDb);
      const rule = new RRule(this.$root.createRruleOpt(rruleDb, schedule.NEXT_DATE, true));
      return rule.toText();
    },

    tranInOut(schedule){
      if(schedule.USER_ID === this.activeUser.ID){
        return parseFloat(schedule.AMOUNT) >= 0 ? 'IN' : 'OUT';
      }

      if(schedule.USER_ID_RELATED === this.activeUser.ID){
        return parseFloat(schedule.AMOUNT) < 0 ? 'IN' : 'OUT';
      }
    },

    toggleSchedule(idx){
      if(this.activeScheduleIdx === idx){
        this.activeScheduleIdx = null;
      }else{
        this.activeScheduleIdx = idx;

        //console.log(this.activeSchedule);
        //console.log(idx);
        //console.log(this.activeScheduleTrans);
      }

    },

    goToAddNewSchedule(){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/tran/new', query: { userId: this.activeUser.ID, tranBaId: null, io: 'in', isSchedule:'1' }});
    },



  },



  created() {
    this.userId = this.$route.query.userId;
    this.initialTransLoad = this.$route.query.initialTransLoad;
    this.tranBaId = this.baId;
    this.familyUsers = JSON.parse(localStorage.getItem('familyUsers'));

    this.loadData();





  },



  mounted() {

    //if(this.isBudget === '1') this.saveBtnTxt = 'Add New Budget';
    //this.getFamilyAccounts();
    //this.getMemberData();



    if(this.initialTransLoad){
      this.getTransactions();
    }


  }
}
</script>

<style scoped>

#mainContainer{
  margin:70px auto 100px auto;
  padding:20px 10px 10px 10px;
}

section{
  margin:10px 0;
}

.tranBtn, .tranTypeBtn{
  margin:5px;
  width:46%;
  background-color:#f5f5f5;
}





.inBtn.active{
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.outBtn.active{
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}



/*>>>>>>>List 2>>>>>>>>>>>>>>>>>>*/

.list2 .avatar img{
  width:40px;
  border:2px solid #9c9c9c;
  border-radius: 50%;
  padding:3px;
}

.list2 li.active{
  border:2px solid #0e90d2;
}

.list2 li{
  position:relative;
  background-color: #f5f5f5;
  border: 2px solid #fff;
  margin: 1px;
}

.list2 li.isBudget{
  background-color: #f2f5a8;
}

.email{
  font-size:12px;
}




.selectDiv{
  position:absolute;
  right:10px;
  width:50px;
}

.selectSpot{
  color:#fff;
}

li.active .selectSpot{
  color: #0e90d2;
}






/*<<<<<<<<<<<<<<List 2<<<<<<<<<<<<<<*/



input.tranAmount{
  height:120px;
  font-size:120px;
  font-weight:bold;
  text-align:center;
  font-family: Arial, sans-serif;
  line-height: 120px;
}



#tranDescContainer{
  margin-bottom:50px;
}

#Details{
  background-color: #f5f5f5;
}


.completeBtn{
  width:100%;
  border-radius: unset;
  height:70px;
}

.fromToLabel{
  text-align: left;
  float:left;
  font-weight:bold;
}

.carousel__item{

}


.avatarDiv img{
  width:130px;
}



@media only screen and (max-width: 600px) {


  input.tranAmount{
    height:60px;
    font-size:50px;
    font-weight:bold;
    line-height: 60px;
  }

  .avatarDiv img{
    width:100px;
  }



}






.CompleteBtnDiv{
  width: calc(100vw + 20px);
  left:-10px;
  height:70px;
  position:fixed;bottom:0;display:flex;align-items:center;justify-content: center;z-index:1001;
}

.clearBtn{
  font-size:10px;
  margin:5px;
}

.hasError{
  border:1px solid red;
}




.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

#actionBtnsContainer {
  position: fixed;
  bottom: 100px;
  right: 50px;
  height: 45px;
  color: #fff;
}

#actionBtnsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionDiv {
  margin-left: 10px;

}

.actionBtn {
  width: 45px;
  height: 45px;
  background-color: #0dcaf0;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.actionTxt {
  color: #3b3b3b;
  font-size: 8px;
  height: 10px;
  text-align: center;
}

.modal-dialog.fullwidth {
  width: 100% !important;
  max-width: 100% !important;
}

.delYes {
  background-color: red;
  color: #fff;
}

.delConfirmBtn {
  text-align: center;
  border: 1px solid #f5f5f5;
  margin: 10px;
}

.delNo {
  background-color: #e4e4e4;
  color: #3b3b3b;
}

.delConfirmBtn svg {
  margin-right: 10px;
}










.tranAccounts li{
  margin:5px 0;
  background-color: #e8e5e5;
  padding:3px 10px;
  border-radius: 2px;
  font-size:14px;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.extend{
  margin-left:20px;
  font-size:12px;
  padding:5px 10px;
}

.expireIcon{
  margin-right:10px;
}

.expireBanner{
  display:flex;
  align-items: center;
}


.tranTypeBtn.active{
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.showBudgetTxt{
}

.datePickerDiv{

}

.showBudgetDiv{
  margin:15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userBA{
  display: flex!important;
  justify-content: space-between;
  font-size:12px !important;
  padding:5px 10px;
  background-color: #f8f7f7;
  margin: 3px 5px;
  align-items: center;
  border-radius: 5px;
}


.info {
  display: flex;
  align-items: center;
}

.accountIcon{
  color: #0dcaf0;
  margin-right: 10px;
}

.totalLine{
  display: flex;
  justify-content: space-between;
  padding:10px 10px 0 20px;
  font-size:12px;
  font-weight: bold;
}

.loadTransDiv{
  display:flex;
  justify-content: center;
  margin:20px;
}

.loadTransBtn{
  background-color: #0dcaf0;
  color:white;
}

.sectionHeader{
  color:#0dcaf0;
  padding:20px 0;
  font-size:20px;
  text-align: center;
}

.transHistory{
  padding:0 10px;
}

.transHistory section{
  margin:10px 0;
}

.fa-toggle-on{
  color: #eff60a !important;
}








/*modal*/
.modalParent :deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalParent :deep(.modal-content){
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width:unset;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 14px;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0 solid #e2e8f0;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.toggleRow{
  display:flex;
  justify-content: space-between;
  margin-top:30px;
}

.toggleFa{
  font-size:30px;
  margin-right:10px;
}

.loadMore{
  text-align: center;
  width:200px;
  margin:20px auto;
}



/*carousel*/
.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.5;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.2);
}



/*scheduleSection*/

.scheduleLi{

  font-size:12px;
  background-color: #f8f7f7;
  margin: 20px 5px;
  border-radius: 5px;
  border:1px solid #9c9c9c;
}

.scheduleTxt{
  background-color: #a4a415;
  padding:5px;
  color:#ffffff;
}

.scheduleTxt span{
  margin-left:20px;
}


.scheduleDetails{
  display: flex!important;
  justify-content: space-between;
  font-size:12px !important;
  padding:5px 10px;
  margin: 3px 5px;
  align-items: center;
  border-radius: 5px;
}

.scheduleDetailsLeft{

}

.inout{
  display: flex;
  align-items: center;
  width:35px;
  height:35px;
  font-size:11px;
  border-radius: 50%;

  justify-content: center;
  text-align: center;
  margin-right:30px;
  color:#ffffff;
}

.scheduleSection .in{
  background-color: #28a745;

}

.scheduleSection .out{
  background-color: #dc3545;
}


.myAccount{
  color: #0dcaf0;
  font-weight: bold;
}



.scheduleSection .amount{
  text-align: right;
}

.tranDesc{
  background-color: #f6f6c6;
  padding:5px 20px;
  border-radius: 5px;
}



.scheduleActions{
  justify-content: center;
  align-items: center;
  margin:10px;
}

.scheduleActionBtn{
  margin:5px 10px;
  font-size:12px;
  padding:5px 10px;
}

.scheduleTransDiv{
  padding:10px;
}

.scheduleTranLi{

  font-size:12px;
  background-color: #f8f7f7;
  margin: 10px 20px;
  border-radius: 5px;

}




</style>

<style>
.carousel__prev{
  left:20px  !important;;
}

.carousel__next{
  right:20px  !important;;
}

.carousel__prev, .carousel__next{
  background-color: #0dcaf0 !important;
}

.userGoal canvas{
  max-height:250px !important;
}




</style>
